/*
 |-----------------------------------------------------------------------------
 | components/atoms/Picture/Picture.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.background {
	display: block;

	.image {
		object-fit: cover;
		width: 100%;
	}
}

.duotone {
	background-color: $brand-1;

	.image {
		filter: grayscale(100%) contrast(1);
		mix-blend-mode: multiply;
	}

	&::before {
		@include absolute(0, 0, 0, 0);
		background-color: $neutral-100;
		mix-blend-mode: lighten;
	}
}

.greyscale {
	.image {
		@include transition('filter');
		filter: grayscale(0);
	}

	:hover > & {
		.image {
			filter: grayscale(100%);
		}
	}
}

.overlay {
	position: relative;

	&::before {
		@include absolute(0, 0, 0, 0);
		@include transition('opacity');
		@include z-index('above');
		background-color: $brand-1;
		content: '';
		opacity: 0;
	}

	//:hover > & {
	&::before {
		opacity: 0.5;
	}
	//}
}

.zoom {
	overflow: hidden;

	.image {
		@include transition('transform');
	}

	:hover > & {
		.image {
			@include scale(1.25);
		}
	}
}
