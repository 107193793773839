/*
 |-----------------------------------------------------------------------------
 | pages/404/404.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.body {
	@include container();
}

.content {
	$size: 100vh;

	background-color: $neutral-0;
	min-height: 100vh;
	overflow-x: hidden;
	position: relative;

	:global(.picture) {
		img {
			height: $size;
		}
	}
}

.foreground {
	@include absolute(0, 0, 0, 0);
	padding: mul($grid-gap, 3);
}
